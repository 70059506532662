/* https://qiita.com/reona396/items/8d205d49947b49f54ed5 を参考に一部カスタマイズ */

.react-datepicker {
  --text: var(--chakra-colors-gray-700);
  --negative-text: var(--chakra-colors-white);
  font-family: unset;
  font-size: 0.9rem;
  border-color: var(--chakra-colors-gray-200);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: var(--chakra-fontSizes-md);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: var(--chakra-colors-gray-200);
  overflow: hidden;
  &:hover {
    border-color: var(--chakra-colors-gray-300);
  }
  &:focus-within {
    z-index: 1;
    border-color: var(--chakra-colors-blue-500);
    box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
  }
  > input {
    padding-left: var(--chakra-space-3);
    padding-right: var(--chakra-space-3);
    width: 100%;
    height: 100%;
    outline: 0;
  }
}

.react-datepicker__input-text {
  background-color: transparent;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--chakra-colors-gray-300);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--blue500);
}

.react-datepicker__navigation--next {
  border-left-color: var(--chakra-colors-gray-300);
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--blue500);
}

.react-datepicker__header {
  background-color: var(--chakra-colors-gray-100);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--chakra-colors-gray-200);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--text);
}

.react-datepicker__month {
  background-color: var(--chakra-colors-white);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__time-box {
  background-color: var(--chakra-colors-whit);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  color: var(--text);
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--chakra-colors-gray-200);
}

.react-datepicker__day {
  color: var(--text);
  &.sunday {
    color: var(--chakra-colors-red-500);
  }
  &.saturday {
    color: var(--chakra-colors-blue-500);
  }
  &.react-datepicker__day--selected,
  &.react-datepicker__day--selected:hover,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range {
    background: var(--chakra-colors-teal-400);
    font-weight: normal;
    color: var(--negative-text);
  }
  &.react-datepicker__day--keyboard-selected {
    background: none;
  }
  &.react-datepicker__day--disabled {
    color: var(--chakra-colors-gray-200);
  }
}

.react-datepicker__day:hover {
  background: var(--chakra-colors-gray-200);
}

.react-datepicker__day-name {
  color: var(--text);
}

.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-teal-400);
  font-weight: normal;
  color: var(--negative-text);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--chakra-colors-teal-400);
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--chakra-colors-gray-200);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: var(--chakra-colors-gray-300);
}

.datepicker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  font-weight: bold;
}

.datepicker__header-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: var(--text);
}

.datepicker__header-date__year {
  margin-right: 7px;
}

.datepicker__button {
  font-size: 1.25em;
  color: #757575;
}
